import { isPlatformRequestError } from "../../Context/API/utils";
import { TError } from "../../I18n/TError";

/**
 *
 * @param {Error} err
 * @returns {TError}
 */
export function processError(err) {
  if (err instanceof TError) {
    return err;
  }

  if (isPlatformRequestError(err, 500)) {
    return new TError("error500", "errors");
  }

  if (isPlatformRequestError(err, 400, "EntitlementError")) {
    return new TError("missingEntitlement", "errors");
  }

  if (
    isPlatformRequestError(
      err,
      401,
      "RequestAuthorizationRequired",
      "UserInteractionRequired"
    )
  ) {
    return new TError("requestAuthorizationFailed", "errors");
  }

  return new TError("genericMessage", "errors");
  // TODO: handle network errors with better message
}
