import { MsalProvider } from "@azure/msal-react";
import CssBaseline from "@mui/material/CssBaseline";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { API_ENDPOINT_PREFIX, COMMIT_ID, DEV_BAR_COLOR } from "./AppConfig";
import AppRoutes from "./AppRoutes";
import { CustomNavigationClient } from "./Context/UserAuth/msal/CustomNavigationClient";
import { DevBar } from "./Features/DevBar";
import { SnackbarProvider } from "./Features/Snackbar";

function App({ pca }) {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <DevBar
        color={DEV_BAR_COLOR}
        commitId={COMMIT_ID}
        apiEndpointPrefix={API_ENDPOINT_PREFIX}
      />
      <SnackbarProvider>
        <CssBaseline />
        <AppRoutes />
      </SnackbarProvider>
    </MsalProvider>
  );
}

App.propTypes = {
  pca: PropTypes.object,
};

export default App;
